<template>
  <div :style="{ opacity: opacityValue, position: 'relative' }">
    <v-sparkline color="color" :gradient="[`${color}E6`, `${color}33`, `${color}00`]" :model-value="data" height="50"
      line-width="1" min="0" padding="0" fill smooth style="position: absolute; bottom: 0; width: 100%;"></v-sparkline>

    <div class="pt-4" style="position: relative; z-index: 1;">
      <div class="ps-4 text-caption d-flex align-center justify-center">{{ title }}</div>

      <v-card-title class="pt-0 mt-n1 d-flex align-center justify-center">
        <div class="text-subtitle-1" :style="{ color: color }">{{ latencyText }}</div>

        <v-chip :color="change.startsWith('-') ? '#2fc584' : '#da5656'"
          :prepend-icon="`mdi-arrow-${change.startsWith('-') ? 'down' : 'up'}`" class="pe-1 ml-1" size="x-small" label>
          <template v-slot:prepend>
            <v-icon size="10"></v-icon>
          </template>

          <span class="text-caption">{{ change }}</span>
        </v-chip>
      </v-card-title>
    </div>
  </div>
</template>


<script>
import { firestore } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";

export default {
  data: () => ({
    latencyText: "--- ms",
    title: 'Estimated latency',
    change: '0.0 %',
    color: '#2fc584',
    data: [0, 0],
    intervalId: null,
    previousLatency: null,
    opacityValue: 1,
  }),
  watch: {
    $route()
    {
      this.$nextTick(this.routeLoaded);
    }
  },
  async mounted()
  {
    await this.$router.isReady();
    this.routeLoaded();
  },
  created()
  {
    this.runUpdate();
  },
  methods: {
    routeLoaded()
    {
      if (["Dashboard", "Search Results", "Artwork", "Repro", "Outsourced items", "Imposition/VPS", "CtP", "Plateout", "Dispatch", "Completed Jobs"].includes(this.$route.name))
      {
        clearInterval(this.intervalId);
        this.runUpdate();
      }
    },
    runUpdate()
    {
      this.opacityValue = 1;
      this.intervalId = setInterval(this.getLatency, 5000);

      setTimeout(() =>
      {
        // After 1 minute, clear the first interval and start a new one that runs every 30 seconds
        clearInterval(this.intervalId);
        this.opacityValue = 0.6;
        this.intervalId = setInterval(this.getLatency, 30000);
      }, 60000); // 1 minute

      setTimeout(() =>
      {
        // After 5 minutes, stop the interval
        clearInterval(this.intervalId);
        this.opacityValue = 0.3;
      }, 300000); // 5 minutes

      // Handle the app losing focus
      document.addEventListener('visibilitychange', () =>
      {
        if (document.visibilityState === 'hidden')
        {
          clearInterval(this.intervalId);
          this.opacityValue = 0.4;
        }
      });
    },
    async getLatency()
    {
      let startTime = Date.now(); // Capture start time
      const docRef = doc(firestore, "analytics", "maintenance");
      const docSnap = await getDoc(docRef);
      let endTime = Date.now(); // Capture end time
      let latency = (endTime - startTime); // Latency in milliseconds
      this.latencyText = `${latency} ms`;

      // Store only the last 10 latency values
      if (this.data.length >= 10)
      {
        this.data.shift();
      }
      if (latency > 1000)
      {
        this.data.push(1000);
      } else
      {
        this.data.push(latency);
      }

      if (this.previousLatency !== null)
      {
        let difference = ((latency - this.previousLatency) / this.previousLatency) * 100;
        this.change = `${difference.toFixed(2)} %`;
      }
      this.previousLatency = latency;
      if (latency <= 499)
      {
        this.color = '#2fc584';
      } else if (latency >= 500 && latency <= 700)
      {
        this.color = '#D5A718';
      } else
      {
        this.color = '#da5656';
      }
    }
  }
}
</script>
