<template>
  <v-list class="mx-1" :lines="false" density="compact" nav>
    <v-img class="mt-5 mb-8" :src="logoPath"></v-img>

    <v-divider></v-divider>

    <MenuItem prepend-icon="mdi-view-dashboard" title="Dashboard" color="primary" to="/" @click="loadData"> </MenuItem>

    <v-divider></v-divider>

    <MenuItem
      v-if="['Administrator', 'Manager', 'Moderator', 'Designer', 'CTP_Operator', 'Coordinator', 'Agent', 'Customer'].includes(userRole)"
      prepend-icon="mdi-palette"
      title="Artwork"
      color="artwork"
      to="/artworks"
      :badge-value="artwork"
      :show-badge="true"
    >
    </MenuItem>

    <div v-if="['Administrator', 'Manager', 'Moderator', 'Designer', 'CTP_Operator', 'Coordinator', 'Agent'].includes(userRole)">
      <!-- Items with badges or progress circulars -->

      <MenuItem prepend-icon="mdi-palette-swatch" title="Repro" color="repro" to="/jobs/repro" :badge-value="repro" :show-badge="true"> </MenuItem>

      <MenuItem prepend-icon="mdi-arrow-decision" title="Outsource" color="osl" to="/jobs/osl" :badge-value="osl" :show-badge="true"> </MenuItem>

      <MenuItem prepend-icon="mdi-format-wrap-tight" title="Imposition" color="vps" to="/jobs/vps" :badge-value="vps" :show-badge="true"> </MenuItem>

      <MenuItem prepend-icon="mdi-gradient" title="CtP" color="ctp" to="/jobs/ctp" :badge-value="ctp" :show-badge="true"> </MenuItem>

      <MenuItem prepend-icon="mdi-basket-unfill" title="Plateout" color="plateout" to="/jobs/plateout" :badge-value="plateout" :show-badge="true"> </MenuItem>

      <MenuItem prepend-icon="mdi-truck-delivery" title="Dispatch" color="dispatch" to="/jobs/dispatch" :badge-value="dispatch" :show-badge="true"> </MenuItem>

      <MenuItem prepend-icon="mdi-sticker-check-outline" title="Completed" color="completed" to="/jobs/completed"> </MenuItem>

      <v-divider></v-divider>
    </div>

    <!---------------------------------- Gatepass section -->
    <MenuItem
      v-if="['Administrator', 'Manager', 'Moderator', 'Designer', 'CTP_Operator'].includes(userRole)"
      prepend-icon="mdi-gate"
      title="Gate Pass"
      color="primary"
      to="/gatepass"
    >
    </MenuItem>

    <!---------------------------------- Procurement section -->
    <v-list-group v-if="['Administrator', 'Manager', 'Moderator'].includes(userRole)">
      <template v-slot:activator="{ props }">
        <v-list-item slim prepend-icon="mdi-cart" title="Procurement" v-bind="props" density="compact"></v-list-item>
      </template>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item class="sub-item-1" slim title="Quotation" prepend-icon="mdi-file-import" v-bind="props" density="compact"></v-list-item>
        </template>
      </v-list-group>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item class="sub-item-1" slim title="Invoice" prepend-icon="mdi-file-plus" v-bind="props" density="compact"></v-list-item>
        </template>
        <MenuItem class="sub-item-2" title="Upload" color="primary" to="/procurement/invoice/upload"> </MenuItem>
        <MenuItem class="sub-item-2" title="Verify" color="primary" to="/procurement/invoice/verify"> </MenuItem>
        <MenuItem class="sub-item-2" title="Approve" color="primary" to="/procurement/invoice/approve"> </MenuItem>
        <MenuItem class="sub-item-2" title="Sent" color="primary" to="/procurement/invoice/sent"> </MenuItem>
      </v-list-group>
    </v-list-group>
    <v-list-group v-if="['Accountant'].includes(userRole)">
      <template v-slot:activator="{ props }">
        <v-list-item slim prepend-icon="mdi-finance" title="GRN Invoices" v-bind="props" density="compact"></v-list-item>
      </template>
      <MenuItem title="Approve" color="primary"> </MenuItem>
      <MenuItem title="ZIV View" color="primary" to="/procurement/invoice/sent" prepend-icon="mdi-eye-outline"> </MenuItem>
    </v-list-group>

    <!---------------------------------- Inventory section -->
    <v-list-group v-if="['Administrator', 'Manager', 'Moderator', 'Designer', 'CTP_Operator'].includes(userRole)">
      <template v-slot:activator="{ props }">
        <v-list-item slim prepend-icon="mdi-package-variant-closed" title="Inventory" v-bind="props" density="compact"></v-list-item>
      </template>
      <MenuItem title="Plates" color="primary" to="/inventory/plates"> </MenuItem>
      <MenuItem title="Films" color="primary" to="/inventory/films"> </MenuItem>
    </v-list-group>

    <!---------------------------------- Billing section -->
    <v-list-group v-if="['Administrator', 'Manager', 'Moderator'].includes(userRole)">
      <template v-slot:activator="{ props }">
        <v-list-item slim prepend-icon="mdi-wallet" title="Billing" v-bind="props" density="compact"></v-list-item>
      </template>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item class="sub-item-1" slim title="Artwork" prepend-icon="mdi-television-guide" v-bind="props" density="compact"></v-list-item>
        </template>
        <MenuItem class="sub-item-2" title="Charges" color="primary" to="/billing/artwork/charges"> </MenuItem>
        <MenuItem class="sub-item-2" title="Generated" color="primary" to="/billing/artwork/generated"> </MenuItem>
      </v-list-group>

      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item class="sub-item-1" slim title="Plates" prepend-icon="mdi-buffer" v-bind="props" density="compact"></v-list-item>
        </template>
        <MenuItem class="sub-item-2" title="Create" color="primary" to="/billing/plates/create"> </MenuItem>
        <MenuItem class="sub-item-2" title="Approve" color="primary" to="/billing/plates/approve"> </MenuItem>
        <MenuItem class="sub-item-2" title="Sent" color="primary" to="/billing/plates/sent"> </MenuItem>
      </v-list-group>
    </v-list-group>

    <!---------------------------------- Control Panel section -->
    <v-list-group v-if="['Administrator', 'Manager', 'Moderator'].includes(userRole)">
      <template v-slot:activator="{ props }">
        <v-list-item slim prepend-icon="mdi-cog" title="Control Panel" v-bind="props" density="compact"></v-list-item>
      </template>

      <div v-if="['Administrator', 'Manager'].includes(userRole)">
        <MenuItem title="Users" color="primary" to="/users"> </MenuItem>
        <MenuItem title="Customers" color="primary" to="/customers"> </MenuItem>
        <MenuItem title="Agents" color="primary" to="/agents"> </MenuItem>
        <MenuItem title="Factory" color="primary" to="/factories"> </MenuItem>
        <MenuItem title="Locations" color="primary" to="/locations"> </MenuItem>
        <MenuItem title="Materials" color="primary" to="/materials"> </MenuItem>
        <MenuItem title="Plate Sizes" color="primary" to="/plateSizes"> </MenuItem>
        <MenuItem title="Printing Machines" color="primary" to="/machines"> </MenuItem>
        <MenuItem title="Job Types" color="primary" to="/types"> </MenuItem>
        <MenuItem title="Outbox" color="primary" to="/outbox"> </MenuItem>

        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item slim title="Automation" v-bind="props" density="compact"></v-list-item>
          </template>
          <MenuItem class="sub-item-2" title="Reports" color="primary" to="/automation/reports"> </MenuItem>
          <MenuItem class="sub-item-2" title="Sales Invoice" color="primary" to="/automation/invoice"> </MenuItem>
        </v-list-group>
      </div>
      <div v-if="['Moderator'].includes(userRole)">
        <MenuItem title="Customers" color="primary" to="/customers"> </MenuItem>
        <MenuItem title="Agents" color="primary" to="/agents"> </MenuItem>
      </div>
    </v-list-group>

    <!---------------------------------- Logs section -->
    <MenuItem v-if="['Administrator', 'Manager'].includes(userRole)" prepend-icon="mdi-format-list-bulleted" title="Logs" color="primary" to="/logs"> </MenuItem>
  </v-list>

  <div class="text-caption text-center" style="opacity: 0.2">version {{ applicationVersion }}</div>
</template>

<script>
import bus from "vue3-eventbus";
import MenuItem from "../Drawer/MenuItem";
import pkg from "../../../../package.json";
import { firestore } from "@/firebase";
import { doc, getDoc, getDocs, collection, query, where, onSnapshot, getCountFromServer } from "firebase/firestore";

export default {
  components: {
    MenuItem,
  },
  data: () => ({
    applicationVersion: pkg.version,
    logoPath: null,
    userEmail: localStorage.email,
    userRole: localStorage.userRole,
    userName: localStorage.userName,
    location: localStorage.location,
    artwork: 0,
    repro: 0,
    osl: 0,
    vps: 0,
    ctp: 0,
    plateout: 0,
    dispatch: 0,
    completed: 0,
  }),
  async created() {
    const { userRole, userName, email } = localStorage;
    this.userRole = userRole;
    this.userName = userName;
    this.userEmail = email;

    bus.on("reloadJobCount", (location) => {
      this.location = location;
      if (this.userRole === "Customer") {
        this.getCustomerAnalytics();
      } else if (this.userRole === "Agent") {
        this.getAgentAnalytics();
      } else {
        this.getAnalytics();
      }
    });

    this.logoPath =
      process.env.NODE_ENV == "development" ||
      ["beta.workflow.printcarepremedia.com", "ppm-workflow-dev.web.app", "ppm-workflow-dev.firebaseapp.com"].includes(window.location.hostname)
        ? require("@/assets/underConstruction.png")
        : require("@/assets/ppmlogo.svg");

    if (this.userRole === "Customer") {
      this.getCustomerAnalytics();
    } else if (this.userRole === "Agent") {
      this.getAgentAnalytics();
    } else {
      this.getAnalytics();
    }
  },
  beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    loadData() {
      if (this.userRole == "Agent") {
        this.getAgentAnalytics();
      } else if (this.userRole == "Customer") {
        this.getCustomerAnalytics();
      }
    },
    async getAnalytics() {
      this.unsubscribe?.();

      ["artwork", "repro", "osl", "vps", "ctp", "plateout", "dispatch"].forEach((prop) => (this[prop] = null));

      const docRef = doc(firestore, "analytics", "currStates", this.location ? "locations" : "", this.location || "");

      this.unsubscribe = onSnapshot(docRef, (docSnapshot) => {
        this.artwork = docSnapshot.data().artwork;
        this.repro = docSnapshot.data().repro;
        this.osl = docSnapshot.data().osl;
        this.vps = docSnapshot.data().vps;
        this.ctp = docSnapshot.data().ctp;
        this.plateout = docSnapshot.data().plateout;
        this.dispatch = docSnapshot.data().dispatch;
      });
    },
    async getAgentAnalytics() {
      ["artwork", "repro", "osl", "vps", "ctp", "plateout", "dispatch"].forEach((prop) => (this[prop] = null));

      const docRef = doc(firestore, "users", localStorage.uid);
      const docSnap = await getDoc(docRef);
      let selectedAgents = docSnap.exists() && docSnap.data().agents ? docSnap.data().agents : [];

      this.artwork = await this.getCountForAgent("artworks", "artwork", selectedAgents);
      this.repro = await this.getCountForAgent("jobs", "repro", selectedAgents);
      this.osl = await this.getCountForAgent("jobs", "osl", selectedAgents);
      this.vps = await this.getCountForAgent("jobs", "vps", selectedAgents);
      this.ctp = await this.getCountForAgent("jobs", "ctp", selectedAgents);
      this.plateout = await this.getCountForAgent("jobs", "plateout", selectedAgents);
      this.dispatch = await this.getCountForAgent("jobs", "dispatch", selectedAgents);
    },
    async getCountForAgent(collectionName, state, selectedAgents) {
      const coll = collection(firestore, collectionName);
      const conditions = [
        where("currState", "==", state),
        where("agent", "in", selectedAgents),
        ...(this.location ? [where("location", "==", this.location)] : []),
        ...(collectionName === "artworks" ? [where("open", "!=", 0)] : []),
      ];

      const q = query(coll, ...conditions);

      const snapshot = await getCountFromServer(q);
      return snapshot.data().count;
    },
    async getCustomerAnalytics() {
      ["artwork"].forEach((prop) => (this[prop] = null));

      const docRef = doc(firestore, "users", localStorage.uid);
      const docSnap = await getDoc(docRef);
      let selectedCustomers = docSnap.exists() && docSnap.data().customers ? docSnap.data().customers : [];

      this.artwork = await this.getCountForCustomer("artworks", "artwork", selectedCustomers);
    },
    async getCountForCustomer(collectionName, state, selectedCustomers) {
      const coll = collection(firestore, collectionName);
      const conditions = [
        where("currState", "==", state),
        where("customer", "in", selectedCustomers),
        ...(this.location ? [where("location", "==", this.location)] : []),
        ...(collectionName === "artworks" ? [where("open", "!=", 0)] : []),
      ];

      const q = query(coll, ...conditions);

      const snapshot = await getCountFromServer(q);
      return snapshot.data().count;
    },
  },
};
</script>

<style scoped>
/* Reduce padding for items under Charge */
.sub-item-1 {
  padding-left: 22px !important;
  /* Adjust this value as needed */
}

.sub-item-2 {
  padding-left: 80px !important;
  /* Adjust this value as needed */
}
</style>
