<template>
    <v-dialog v-model="notificationDialog" persistent max-width="600">
        <v-card>
            <v-card-title>Enable Notifications</v-card-title>
            <v-card-text>
                <Vue3Lottie :animationData="Bell" :height="200" :width="300" :speed="1" />
                It looks like you've disabled notifications. Would you like to enable them to stay updated?
            </v-card-text>
            <v-card-actions>
                <v-checkbox v-model="dontShowAgain" label="Don't show again"></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="requestNotificationPermission">Enable</v-btn>
                <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Bell from "@/assets/animjson/bell.json";
import pkg from "../../../package.json";
import { getToken } from "firebase/messaging";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { messaging, firestore } from "@/firebase"; // Assuming these are set up

export default {
    name: "AllowNotification",
    data() {
        return {
            notificationDialog: false,
            dontShowAgain: false,
            Bell,
        };
    },
    methods: {
        checkNotificationPermission() {
            const permission = Notification.permission;
            const dontShowAgainState = localStorage.getItem("dontShowAgain") === "true";

            if (permission != "granted" && !dontShowAgainState) {
                this.notificationDialog = true;
            } else {
                this.retrieveFCMToken();
            }

            console.log("Notification permission " + permission);
        },
        requestNotificationPermission() {
            this.notificationDialog = false;
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    localStorage.setItem("dontShowAgain", "true");
                    this.retrieveFCMToken();
                }
                this.notificationDialog = false;
                if (this.dontShowAgain) {
                    localStorage.setItem("dontShowAgain", "true");
                }
            });
        },
        async retrieveFCMToken() {
            const permission = Notification.permission;
            let vapidKeyString = null;
            if (process.env.NODE_ENV === "development" || ["beta.workflow.printcarepremedia.com", "ppm-workflow-dev.web.app", "ppm-workflow-dev.firebaseapp.com"].includes(window.location.hostname)) {
                vapidKeyString = 'BIaNsf3koT5uOtTTlbDmKZ1vp3FyepSIalniXCr5ufJE8fR77CmnZYv5KMDEpOgXfmgKAmoP7mDolExwaJpwD5U';
            } else if (process.env.NODE_ENV == "production") {
                vapidKeyString = 'BLRvshdbZJcwoxxt8iD4w0dchayn3JJAsHyK9llssSP6MeHupwhQ4jdIkeEzHMAkS4KG770jL2iqrMr3Y_nJGgE';
            }

            if (permission == "granted") {
                try {
                    const currentToken = await getToken(messaging, { vapidKey: vapidKeyString });

                    if (!currentToken) {
                        console.error("No registration token available.");
                        return;
                    }

                    if (process.env.NODE_ENV === "development" || ["beta.workflow.printcarepremedia.com", "ppm-workflow-dev.web.app", "ppm-workflow-dev.firebaseapp.com"].includes(window.location.hostname)){
                        localStorage.fcmToken = currentToken;
                    }

                    const userRef = doc(firestore, "users", localStorage.uid);
                    const userDoc = await getDoc(userRef);

                    const tokens = userDoc.exists()
                        ? userDoc.data().tokens || []
                        : [];

                    const metadata = {
                        deviceType: this.getDeviceType(),
                        browser: navigator.userAgent.includes("Chrome")
                            ? "Chrome"
                            : navigator.userAgent.includes("Firefox")
                                ? "Firefox"
                                : "Other",
                        platform: navigator.platform,
                        appVersion: pkg.version,
                    };

                    const updatedTokens = [
                        ...tokens.filter((t) => t.token !== currentToken), // Remove duplicate
                        { token: currentToken, ...metadata, lastUpdated: new Date().toISOString() }
                    ].slice(-5); // Keep only the last 5

                    await setDoc(userRef, { tokens: updatedTokens }, { merge: true });
                } catch (err) {
                    console.error("Error retrieving token:", err);
                }
            }
        },
        closeDialog() {
            this.notificationDialog = false;
            if (this.dontShowAgain) {
                localStorage.setItem("dontShowAgain", "true");
            }
        },
        getDeviceType() {
            const userAgent = navigator.userAgent.toLowerCase();
            const isMobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
            const isTablet = /ipad|tablet|nexus 7|nexus 10|kfapwi/i.test(userAgent);

            if (isTablet) return "tablet";
            if (isMobile) return "mobile";
            return "desktop";
        }
    },
    mounted() {
        this.checkNotificationPermission();
    },
};
</script>